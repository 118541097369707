.button {
  padding: 1rem;
  width: auto;
  height: 6rem;
  font-size: 3rem;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: white;
  font-weight: bold;
  border-radius: 1rem;
  cursor: pointer;
  white-space: nowrap;
  margin: 1rem;
}

.button:hover {
  background-color: var(--lightest-purple);
  color: #ffffff;
}
