.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: rgba(6, 82, 221);
}

.modal p {
  white-space: normal;
  text-align: center;
}

.header h2 {
  margin: 0;
  color: white;
}

.content {
  padding: 2rem;
  font-size: 3rem;
  color: white;
}

.actions {
  padding: 2rem;
  display: flex;
  justify-content: flex-end;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 30rem);
    width: 60rem;
  }
}
