.card {
  display: flex;
  justify-content: space-between;
  margin: 0.6rem;
  /* background-color: var(--dark-purple); */
  background-color: rgba(6, 82, 221, 0.5);
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.8rem rgba(0, 0, 0, 0.2);
  padding: 1rem;
  font-size: 2.5rem;
  color: white;
}
