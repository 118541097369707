.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-content h1 {
  color: white;
  text-align: center;
  margin-bottom: 4rem;
  font-size: 6rem;
}

.main-content .btn {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.8);
  color: white;
  font-weight: bold;
  border-radius: 1rem;
  cursor: pointer;
  white-space: nowrap;
  padding: 1rem;
  margin: 0 1.5rem;
}

.main-content .btn:hover {
  background-color: var(--lightest-purple);
  color: white;
}

.main-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.main-content li a {
  text-decoration: none;
  font-size: 3rem;
  color: white;
}
