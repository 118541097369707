.spinner {
  display: inline-block;
  width: 8rem;
  height: 8rem;
}
.spinner:after {
  content: ' ';
  display: block;
  width: 6.4rem;
  height: 6.4rem;
  margin: 0.8rem;
  border-radius: 50%;
  border: 1rem solid var(--darkest-purple);
  border-color: var(--darkest-purple) transparent var(--darkest-purple)
    transparent;
  animation: spinner 1s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
