.header {
  padding: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 10rem;
}

.logo {
  padding: 1rem;
}

.logo a {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 3rem;
  color: white;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
  transition: all 0.5s;
}

.logo a:hover {
  transform: rotate(360deg);
}

nav {
  flex-grow: 1;
}

.header__links {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* .header nav #hidden {
  display: flex;
} */

/* .header__links li {
  padding: 0 1rem;
  margin: 0 1.5rem;
} */

.header__links a:link,
.header__links a:visited,
.header__links p {
  border-style: none;
  text-decoration: none;
  font-size: 2.3rem;
  color: white;
  padding: 0.5rem 1rem;
  margin: 0 1.5rem;
  white-space: nowrap;
  transition: all 0.3s ease;
  position: relative;
}

.header__links a:hover {
  background-color: var(--lightest-purple);
  border-radius: 1rem;
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
}

.header__links a.active {
  background-color: var(--lightest-purple);
  border-radius: 1rem;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.header__links a::after {
  content: '';
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s ease;
}

.header__links a:hover::after {
  transform: scale(1.5);
  background-color: var(--lightest-purple);
  opacity: 0;
}

.header__links .btn--container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 5rem;
}

.header__links .btn--container li {
  margin: 0;
  padding: 0;
}

.header nav .btn {
  padding: 0.5rem 1rem;
  margin: 0 1.5rem;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.8);
  font-weight: bold;
  border-radius: 1rem;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.header .btn:hover {
  background-color: var(--lightest-purple);
  color: white;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 5rem;
  z-index: -1;
}
.footer p {
  text-align: center;
  font-size: 1.5rem;
  color: white;
}

.nav--clock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nav--clock p {
  white-space: normal;
  text-align: center;
}

.header__menuicon {
  display: none;
  width: 5rem;
  height: 5rem;
  color: white;
}

.mainc {
  min-height: calc(100% - 15rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 5rem 0;
}

@media screen and (max-width: 600px) {
  .header__links {
    display: none;
  }
  .header__menuicon {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin: 3rem;
  }
  #hidden {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 14rem;
    left: 0;
    width: 100%;
    height: 45rem;
    justify-content: space-between;
    background-color: var(--dark-purple);
    padding: 3rem;
    z-index: 100;
  }

  #hidden a {
    width: 100%;
    text-align: center;
  }

  .header__links .btn--container {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    width: 100%;
  }
  .header__links .btn--container .btn {
    margin: 1.5rem 0;
    border-style: none;
  }

  .header__menuicon.active {
    background-color: var(--lightest-purple);
    border-radius: 1rem;
    padding: 1rem;
    z-index: 100;
  }
}
