:root {
  /* --dark-purple: #6c5ce7;
  --darker-purple: #4834d4;
  --darkest-purple: #341f97;
  --lighter-purple: #9b59b6;
  --light-purple: #8e44ad;
  --lightest-purple: #c291e2; */
  --dark-purple: #0652dd;
  --darker-purple: #1e3799;
  --darkest-purple: #0c2461;
  /* --lighter-purple: #9b59b6;
  --light-purple: #8e44ad; */
  --lightest-purple: #74b9ff;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

#root {
  height: 100%;
}

html {
  font-size: 62.5%;
  height: 100%;
}

body {
  font-family: 'Lato', sans-serif;
  background-image: linear-gradient(
      to right bottom,
      rgba(6, 82, 221, 0.9),
      rgba(6, 82, 221, 0.5)
        /* rgb(72, 52, 212, 0.9), 
      rgb(108, 92, 231, 0.2) */
    ),
    url(./assets/pic/main-1200.jpg);
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  box-sizing: border-box;
  height: 100%;
}

.centered {
  margin: 5rem auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-resolution: 192dpi) and (min-width: 600px),
  (min-width: 1200px) {
  body {
    background-image: linear-gradient(
        to right bottom,
        rgba(6, 82, 221, 0.9),
        rgba(6, 82, 221, 0.5)
          /* rgb(72, 52, 212, 0.9), 
        rgb(108, 92, 231, 0.2) */
      ),
      url(./assets/pic/main-2000.jpg);
  }
}

@media screen and (max-width: 1200px) {
  html {
    font-size: 50%; /* 8px per rem */
  }
}

@media screen and (max-width: 900px) {
  html {
    font-size: 37.5%; /* 6px per rem */
  }
}
